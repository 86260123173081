import logo from './logo.svg';
import './App.css';

import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">   
          <h1>Daily Dose AI</h1>
          <a target="_blank" rel="noopener noreferrer" href="https://techcrunch.com/category/artificial-intelligence/">TechCrunch</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.producthunt.com/topics/artificial-intelligence">Product Hunt</a>
          <a target="_blank" rel="noopener noreferrer" href="https://huggingface.co/papers">Hugging Face</a>
          <a target="_blank" rel="noopener noreferrer" href="https://news.mit.edu/topic/artificial-intelligence2">MIT News - Artificial Intelligence</a>
          <a target="_blank" rel="noopener noreferrer" href="https://bair.berkeley.edu/blog/">Berkeley Artificial Intelligence Research Blog</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.analyticsvidhya.com/blog/">Analytics Vidhya</a>
          <a target="_blank" rel="noopener noreferrer" href="https://towardsdatascience.com/">Towards Data Science</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.kdnuggets.com/">KDnuggets</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.sciencedaily.com/news/computers_math/artificial_intelligence/">ScienceDaily - Artificial Intelligence News</a>
          <a target="_blank" rel="noopener noreferrer" href="https://blogs.nvidia.com/">NVIDIA Blog</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.marktechpost.com/">MarkTechPost</a>
          <a target="_blank" rel="noopener noreferrer" href="https://blog.tensorflow.org/">TensorFlow Blog</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.deepmind.com/blog">DeepMind Blog</a>
          <a target="_blank" rel="noopener noreferrer" href="https://pub.towardsai.net/">Towards AI</a>
          <a target="_blank" rel="noopener noreferrer" href="https://blog.ml.cmu.edu/">CMU Machine Learning Department Blog</a>
          <a target="_blank" rel="noopener noreferrer" href="https://theresanaiforthat.com/">TheresaNaiForThat</a>
          <a target="_blank" rel="noopener noreferrer" href="https://theresanaiforthat.com/most-saved/#switch">TheresaNaiForThat - Most Saved</a>
          <a target="_blank" rel="noopener noreferrer" href="https://ofemwire.com/">OfemWire</a>
          <p></p>
        </header> 
    </div>
  );
}

export default App;